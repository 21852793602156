.GlobalFeedback {
  &-button {
    text-shadow: none;
    position: fixed;
    right: -23px;
    top: 50%;
    padding: 0.5rem 1.5rem;
    background-color: #eee;
    border: 1px solid #aaa;
    border-radius: 0 0 4px 4px;
    transform: rotate(90deg) translateY(-50%);
    font-weight: 700;
    color: #333;
    z-index: 10000;

    &:hover {
      right: -21px;
    }
    &:active {
      background-color: #ddd;
    }
  }
}
